import React from 'react'
import Clipboardcopy from './Clipboardcopy'

const DISCORD_LINK = "https://discord.gg/ytUXq9mHUT"

function Contact () {
  return (
    <>
      <div className='flex justify-center align-middle bg-color-background-default border-black w-full py-6'>
        <a href={DISCORD_LINK}>
        <button className='bg-green-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
          Contact Me <br></br> [Discord Link]
        </button>
        </a>
        <div className='text-white ml-4 grid grid-cols-1 content-center '>
          <p><b>@</b></p>
        </div>
        <div className='mx-4 content-center grid grid-cols-1'>
          <Clipboardcopy toClipText = {DISCORD_LINK}/>
        </div>
      </div>
    </>
  )
}

export default Contact
